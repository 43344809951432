
import { Options, Vue } from "vue-class-component";

@Options({
  mounted() {
    this.loadJSON((teadb: string) => {
      this.tealist = JSON.parse(teadb);
    });
  },
  data() {
    return {
      tealist: [],
      searchedTea: ""
    };
  },
  methods: {
    loadJSON(callback: (arg0: string) => void) {
      const xobj = new XMLHttpRequest();
      xobj.overrideMimeType("application/json");
      xobj.open("GET", "/DB.json", true);
      xobj.onreadystatechange = function() {
        if (xobj.readyState == 4 && xobj.status == 200) {
          callback(xobj.responseText);
        }
      };
      xobj.send(null);
    },
    getRandomTea() {
      this.$router.push({
        path: `/tea/${
          this.tealist[Math.floor(this.tealist.length * Math.random())].Codice
        }`
      });
    },
    gotoSelectedTea(code: string) {
      this.$router.push({ path: `/tea/${code}` });
    },
    hideSoftKeyboard() {
      setTimeout(function() {
        const field = document.createElement("input");
        field.setAttribute("type", "text");
        field.setAttribute(
          "style",
          "position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;"
        );
        document.body.appendChild(field);
        field.onfocus = function() {
          setTimeout(function() {
            field.setAttribute("style", "display:none;");
            setTimeout(function() {
              document.body.removeChild(field);
              document.body.focus();
            }, 14);
          }, 200);
        };
        field.focus();
      }, 50);
    }
  },
  computed: {
    filteredTea: function() {
      return this.tealist.filter((i: { Titolo: string; Codice: string }) => {
        return (
          i.Titolo.toLowerCase().includes(this.searchedTea.toLowerCase()) ||
          i.Codice.toString().includes(this.searchedTea)
        );
      });
    }
  }
})
export default class Home extends Vue {}
