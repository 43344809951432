
import { Options, Vue } from "vue-class-component";

@Options({
  mounted() {
    this.loadJSON((teadb: string) => {
      this.teainfo = JSON.parse(teadb).filter((i: { Codice: string }) => {
        return i.Codice == this.$route.params.teaid;
      })[0];
      this.shortDesc = this.teainfo.Descrizione.substr(0, 100);
    });
    // window.addEventListener("scroll", this.reloadBottomMenu);
    window.onbeforeunload = function() {
      return "Sei sicuro di voler uscire?";
    };
  },
  data() {
    return {
      teainfo: {},
      clicked: false,
      shortDesc: ""
    };
  },
  methods: {
    loadJSON(callback: (arg0: string) => void) {
      const xobj = new XMLHttpRequest();
      xobj.overrideMimeType("application/json");
      xobj.open("GET", "/DB.json", true);
      xobj.onreadystatechange = function() {
        if (xobj.readyState == 4 && xobj.status == 200) {
          callback(xobj.responseText);
        }
      };
      xobj.send(null);
    },
    reloadBottomMenu() {
      document.getElementById("bottom_menu")!.style.display = "none";
      document.getElementById("bottom_menu")!.style.position = "inherit";
      document.getElementById("bottom_menu")!.style.bottom = "0px";
      document.getElementById("bottom_menu")!.style.display = "block";
    },
    descriptionClick() {
      this.clicked = !this.clicked;
      this.reloadBottomMenu();
    },
    openUrl(url: string) {
      window.open(url!, "");
    }
  },
  computed: {
    toggleDesc() {
      if (this.clicked) {
        return this.teainfo.Descrizione;
      } else return this.shortDesc;
    }
  }
})
export default class TeaInfo extends Vue {
  teainfo!: JSON;
}
