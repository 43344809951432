
import { Options, Vue } from "vue-class-component";

@Options({
  mounted() {
    //window.addEventListener("scroll", this.reloadBottomMenu);
    window.onbeforeunload = function() {
      return "Sei sicuro di voler uscire?";
    };
  },
  methods: {
    closemodal(modalid: string) {
      if (modalid != null) {
        document!.getElementById(modalid)!.classList.value = "modal";
      }
    },
    openmodal(modalid: string) {
      if (modalid != null) {
        document!.getElementById(modalid)!.classList.value += " is-active";
      }
    },
    openUrl(url: string) {
      window.open(url!, "");
    },
    reloadBottomMenu() {
      document.getElementById("bottom_menu")!.style.display = "none";
      document.getElementById("bottom_menu")!.style.position = "inherit";
      document.getElementById("bottom_menu")!.style.bottom = "0px";
      document.getElementById("bottom_menu")!.style.display = "block";
    }
  }
})
export default class App extends Vue {}
