<template>
  <div class="home">
    <img id="logo" alt="Logo Chazen" src="../assets/logo.jpg" /><br />
    <input
      id="teasearch"
      type="text"
      class="input"
      placeholder="Ricerca un Tè"
      v-model="searchedTea"
      @keyup.enter="hideSoftKeyboard"
    />
    <a id="luckyshot" class="button is-primary" @click="getRandomTea()"
      ><i class="fas fa-crosshairs spaced"></i>Mi sento fortunato</a
    >
    <ul id="tea-list" v-if="searchedTea != String()">
      <router-link
        v-for="tea in filteredTea"
        :key="tea.Codice"
        :to="{ name: 'TeaInfo', params: { teaid: tea.Codice } }"
      >
        <li class="teaelement box">
          <img :src="'./img/tea/' + tea.Codice + '.jpg'" />
          <span>{{ tea.Titolo }}</span>
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  mounted() {
    this.loadJSON((teadb: string) => {
      this.tealist = JSON.parse(teadb);
    });
  },
  data() {
    return {
      tealist: [],
      searchedTea: ""
    };
  },
  methods: {
    loadJSON(callback: (arg0: string) => void) {
      const xobj = new XMLHttpRequest();
      xobj.overrideMimeType("application/json");
      xobj.open("GET", "/DB.json", true);
      xobj.onreadystatechange = function() {
        if (xobj.readyState == 4 && xobj.status == 200) {
          callback(xobj.responseText);
        }
      };
      xobj.send(null);
    },
    getRandomTea() {
      this.$router.push({
        path: `/tea/${
          this.tealist[Math.floor(this.tealist.length * Math.random())].Codice
        }`
      });
    },
    gotoSelectedTea(code: string) {
      this.$router.push({ path: `/tea/${code}` });
    },
    hideSoftKeyboard() {
      setTimeout(function() {
        const field = document.createElement("input");
        field.setAttribute("type", "text");
        field.setAttribute(
          "style",
          "position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;"
        );
        document.body.appendChild(field);
        field.onfocus = function() {
          setTimeout(function() {
            field.setAttribute("style", "display:none;");
            setTimeout(function() {
              document.body.removeChild(field);
              document.body.focus();
            }, 14);
          }, 200);
        };
        field.focus();
      }, 50);
    }
  },
  computed: {
    filteredTea: function() {
      return this.tealist.filter((i: { Titolo: string; Codice: string }) => {
        return (
          i.Titolo.toLowerCase().includes(this.searchedTea.toLowerCase()) ||
          i.Codice.toString().includes(this.searchedTea)
        );
      });
    }
  }
})
export default class Home extends Vue {}
</script>

<style lang="scss" scoped>
#logo {
  width: 50%;
  max-height: 5rem;
  max-width: 10rem;
}

.spaced {
  margin-right: 0.5rem;
}

.teaelement,
#teasearch,
#luckyshot {
  display: flex;
  width: 50%;
  margin: 1rem auto;
}

.teaelement img {
  max-width: 10rem;
  max-height: 10rem;
}

.teaelement span {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: auto auto auto 2rem;
  flex: auto;
}

@media only screen and (max-width: 1080px) {
  .teaelement,
  #teasearch,
  #luckyshot {
    width: 95%;
    margin: 1rem auto;
  }
}

@media only screen and (max-width: 640px) {
  .teaelement img {
    height: 7rem;
    width: 7rem;
  }
}
</style>
