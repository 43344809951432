<template>
  <div class="teainfo">
    <img id="logo" alt="Logo Chazen" src="../assets/logo.jpg" /><br />
    <figure>
      <img id="teapic" :src="'../img/tea/' + $route.params.teaid + '.jpg'" />
    </figure>

    <h2 id="teatitle" class="title">{{ teainfo.Titolo }}</h2>
    <h4 id="teaid" class="subtitle">({{ $route.params.teaid }})</h4>

    <hr />

    <p class="specs">
      <span class="spaced"
        ><i class="fas fa-weight-hanging"></i>{{ teainfo.Quantita }}</span
      >
      <span class="spaced"
        ><i class="fas fa-mug-hot"></i>{{ teainfo.Gradi }}</span
      >
      <span class="spaced"
        ><i class="fas fa-clock"></i>{{ teainfo.Tempo }}</span
      >
    </p>

    <hr />

    <p class="description">
      {{ toggleDesc }}
      <br />
      <a @click="descriptionClick" v-if="!clicked"
        ><strong>[Continua a Leggere]</strong></a
      >
      <a @click="descriptionClick" v-if="clicked"><strong>[Riduci]</strong></a>
    </p>

    <div id="navigation">
      <router-link id="goback" to="/" class="button"
        ><i class="fas fa-search spaced"></i>Cerca ancora</router-link
      >
      <button
        @click="openUrl(teainfo.URL)"
        id="buytea"
        class="button is-primary"
      >
        <i class="fas fa-shopping-cart spaced"></i>Acquista
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  mounted() {
    this.loadJSON((teadb: string) => {
      this.teainfo = JSON.parse(teadb).filter((i: { Codice: string }) => {
        return i.Codice == this.$route.params.teaid;
      })[0];
      this.shortDesc = this.teainfo.Descrizione.substr(0, 100);
    });
    // window.addEventListener("scroll", this.reloadBottomMenu);
    window.onbeforeunload = function() {
      return "Sei sicuro di voler uscire?";
    };
  },
  data() {
    return {
      teainfo: {},
      clicked: false,
      shortDesc: ""
    };
  },
  methods: {
    loadJSON(callback: (arg0: string) => void) {
      const xobj = new XMLHttpRequest();
      xobj.overrideMimeType("application/json");
      xobj.open("GET", "/DB.json", true);
      xobj.onreadystatechange = function() {
        if (xobj.readyState == 4 && xobj.status == 200) {
          callback(xobj.responseText);
        }
      };
      xobj.send(null);
    },
    reloadBottomMenu() {
      document.getElementById("bottom_menu")!.style.display = "none";
      document.getElementById("bottom_menu")!.style.position = "inherit";
      document.getElementById("bottom_menu")!.style.bottom = "0px";
      document.getElementById("bottom_menu")!.style.display = "block";
    },
    descriptionClick() {
      this.clicked = !this.clicked;
      this.reloadBottomMenu();
    },
    openUrl(url: string) {
      window.open(url!, "");
    }
  },
  computed: {
    toggleDesc() {
      if (this.clicked) {
        return this.teainfo.Descrizione;
      } else return this.shortDesc;
    }
  }
})
export default class TeaInfo extends Vue {
  teainfo!: JSON;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#logo {
  width: 50%;
  max-height: 5rem;
  max-width: 10rem;
}
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

#teatitle {
  width: 95%;
  font-size: 1.5rem;
  margin: auto;
}

#teaid {
  font-family: monospace;
  margin: 0.5rem auto;
}

#navigation {
  display: flex;
}

#navigation #goback,
#navigation #buytea {
  margin: 0.2rem;
  width: 100%;
}

.description,
.specs {
  width: 50%;
  margin: 2rem auto;
}

.spaced {
  margin: auto 0.5rem;
}

.spaced svg {
  margin-right: 0.5rem;
}

#buytea {
  margin-bottom: 2rem;
  width: 50%;
}

@media only screen and (max-width: 1080px) {
  #teapic {
    max-width: 40%;
    margin: 1rem auto;
  }

  .description,
  .specs {
    width: 99%;
    margin: 1rem auto;
  }

  #buytea {
    margin-bottom: 2rem;
    width: 99%;
  }
}
</style>
